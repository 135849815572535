import styled, { css } from 'styled-components'

import {
  Box,
  ContentText,
  fontSizes,
  generateColorLuminances,
  hex2rgba,
  spacing,
} from '@fortum/elemental-ui'

import { StyledLink } from '@/shared/components/StyledLink'
import type { LinkEntry } from '@/shared/contentful/types'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'

type TabLinkProps = {
  /**
   * Item to be displayed in the tab
   */
  item:
    | {
        id: string
        name: string
      }
    | (LinkEntry & {
        id: string
        name: string
        slug?: string | null
      })

  /**
   * Function to be called on click
   */
  onClick: VoidFunction
  /**
   * Flag to specify if button is active
   */
  active: boolean
}

export const TabLink = ({ item, onClick, active }: TabLinkProps) => {
  const theme = useTheme()

  const link = ('slug' in item && getLinkEntryUrl(item)) || ''

  return (
    <StyledLink
      key={item.id}
      tabIndex={-1}
      href={link}
      aria-label={item.name}
      title={item.name}
      noUnderlineHover
      data-testid={`tab-item-${item.name}`}
      p={0}
      color={theme.colors.textLink}
      height={spacing.m}
      onClick={onClick}
    >
      <EffectBox
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick()
          }
        }}
        $isActive={active}
        tabIndex={0}
      >
        <Box mb={`-${spacing.xxxxs}`} display="flex" pv={spacing.xxs} ph={spacing.xxxs}>
          <StyledText fontSize={fontSizes.s.s} color={theme.colors.textLink}>
            {item.name}
          </StyledText>
        </Box>
      </EffectBox>
    </StyledLink>
  )
}

const EffectBox = styled(Box)<{ $isActive?: boolean }>`
  ${({ $isActive, theme }) => css`
    color: ${theme.colors.brandPrimary};
    border-bottom: ${$isActive ? `${spacing.xxxxs} solid ${theme.colors.brandPrimary}` : 'none'};

    ${$isActive &&
    css`
      &:hover,
      &:focus,
      &:active {
        border-bottom-color: ${generateColorLuminances('hover', theme).bg};

        ${StyledText} {
          color: ${generateColorLuminances('hover', theme).bg};
        }
      }
    `}

    &:hover {
      background-color: ${hex2rgba(theme.colors.brandPrimary, 0.05)};

      ${$isActive &&
      css`
        border-bottom-color: ${generateColorLuminances('focus', theme).bg};
      `}
    }

    &:active {
      ${StyledText} {
        color: ${generateColorLuminances('click', theme).bg};
      }
      ${$isActive && `border-bottom-color: ${generateColorLuminances('click', theme).bg};`}
    }
  `}
`

const StyledText = styled(ContentText)`
  ${({ theme }) => css`
    color: ${theme.colors.brandPrimary};
  `}
`
