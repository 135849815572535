'use client'

import { useTranslations } from 'next-intl'
import styled, { css } from 'styled-components'

import { Button, IconProfileOutline, generateColorLuminances, spacing } from '@fortum/elemental-ui'

import { Link, usePathname } from '@/shared/navigation'
import { routes } from '@/shared/routes'

export type EnterpriseLoginButtonProps = {
  label?: string
  color?: string
  padding?: string
  height?: string
}

export const EnterpriseLoginButton = ({
  label,
  color,
  padding,
  height,
}: EnterpriseLoginButtonProps) => {
  const t = useTranslations('mainNavigation')
  const pathname = usePathname()

  const loginUrl = 'https://online.fortum.se/login' // TODO Dirty fix. Add to Contetnful

  return (
    pathname !== routes.SERVICE_BREAK && (
      <Link href={loginUrl}>
        <StyledButton
          status="plain"
          leftIcon={IconProfileOutline}
          color={color}
          aria-label={t('login')}
          fontSize={spacing.xxs}
          ph={padding}
          height={height}
          data-testid="enterprise-login-button"
        >
          {label}
        </StyledButton>
      </Link>
    )
  )
}

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    &:hover {
      background: none;
      color: ${generateColorLuminances('hover', theme).bg};
    }

    &:active {
      background: none;
      color: ${generateColorLuminances('click', theme).bg};
    }
  `}
`
