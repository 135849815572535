import FocusTrap from 'focus-trap-react'
import { useTranslations } from 'next-intl'
import { useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'

import {
  Box,
  FortumLogo,
  Gap,
  Grid,
  Hidden,
  IconButton,
  IconSearch,
  generateColorLuminances,
  px2rem,
  spacing,
} from '@fortum/elemental-ui'

import { Icon } from '@/shared/components/Icon'
import { StyledLink } from '@/shared/components/StyledLink'
import type { MainNavigationEntry } from '@/shared/contentful/types'
import { isTypeColumnMenu, isTypeLink } from '@/shared/contentful/types/contentTypeGuards'
import { getLinkEntryUrl } from '@/shared/contentful/utils'
import { useTheme } from '@/shared/hooks/useTheme'
import type { useRouter } from '@/shared/navigation'
import { useFeatureFlag } from '@/shared/providers/featureFlag'
import { rejectNil } from '@/shared/utils/array'

import { EnterpriseLoginButton } from '../../parts/Buttons/EnterpriseLoginButton'
import { LoginButton } from '../../parts/Buttons/LoginButton'
import { UserBadge } from '../../parts/Buttons/UserBadge'
import { CatalogueContainer } from '../../parts/CatalogueContainer'
import { TabLink } from '../../parts/Links/TabLink'

type MainNavigationProps = {
  /**
   * Localized pathname of the current page
   */
  pathname: string
  /**
   * Flag indicating if the top bar is visible
   */
  isTopBarVisible: boolean
  /**
   * Username of the logged in user
   */
  username: string | undefined
  /**
   * Flag indicating if the current page is private
   */
  isPrivate: boolean
  /**
   * Router instance
   */
  router: ReturnType<typeof useRouter>
  /**
   * Name of the selected header item
   */
  selectedHeaderItem?: string
  /**
   * Main navigation items
   */
  mainNavigation: MainNavigationEntry
  /**
   * Function to close the menu
   */
  closeMenu: () => void
  /**
   * Function to change the selected tab
   */
  changeTab: (e: string | number) => void
  /**
   * Height of the main navigation menu
   */
  height: string | number
  /**
   * Reference to the main navigation content
   */
  contentRef: React.RefObject<HTMLDivElement>
  /**
   * Open search callback
   */
  onOpenSearch: () => void
}

/**
 * Main navigation header and menu for desktop
 */
export const MainNavigation = ({
  pathname,
  isTopBarVisible,
  username,
  isPrivate,
  router,
  selectedHeaderItem,
  mainNavigation,
  closeMenu,
  changeTab,
  height,
  contentRef,
  onOpenSearch,
}: MainNavigationProps) => {
  const [navigationZIndex, setNavigationZIndex] = useState(90)
  const t = useTranslations('mainNavigation')
  const theme = useTheme()
  const mainNavigationItems = mainNavigation?.itemsCollection
  const logoLink = mainNavigation?.logoLink
  const activeHeaderBarItem =
    mainNavigationItems?.items &&
    mainNavigationItems.items.find((item) => item?.name === selectedHeaderItem)
  const customerType = mainNavigation?.customerType
  const isEnabled = useFeatureFlag('enableNavigationSearchFields')

  const tabItem = useMemo(
    () =>
      mainNavigationItems?.items
        ? rejectNil(
            mainNavigationItems.items
              .filter((item) => item !== undefined)
              .map((item) => {
                if (item && isTypeLink(item)) {
                  return {
                    ...item,
                    id: item.name ?? '',
                    name: item.label ?? '',
                    slug: item.page?.slug ?? '',
                  }
                }
                if (item && isTypeColumnMenu(item)) {
                  return {
                    id: item.name ?? '',
                    name: item.title ?? '',
                  }
                }
                return {
                  //Fallback in case that new Content Type is introduced for MainNavigation items
                  id: item!.sys?.id ?? '',
                  name: item!.name ?? '',
                }
              }),
          )
        : [],
    [mainNavigationItems?.items],
  )

  const checkActiveTab = (itemId: string) => {
    if (selectedHeaderItem) {
      return selectedHeaderItem === itemId
    }

    // If no header item is selected, check if the item's slug matches the pathname
    const currentItem = tabItem.find((item) => item.id === itemId)
    if (currentItem && 'slug' in currentItem && `/${currentItem.slug}` === pathname) {
      return true
    }

    return false
  }

  const animationDuration = 300

  useEffect(() => {
    const focusFirstElement = () => {
      if (contentRef.current) {
        const firstFocusableElement = contentRef.current.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        )
        if (firstFocusableElement instanceof HTMLElement) {
          firstFocusableElement.focus()
        }
      }
    }

    let timeoutId: ReturnType<typeof setTimeout>

    if (Boolean(selectedHeaderItem)) {
      setNavigationZIndex(100)
      timeoutId = setTimeout(focusFirstElement, animationDuration)
    } else {
      timeoutId = setTimeout(() => setNavigationZIndex(90), animationDuration)
    }
    return () => {
      clearTimeout(timeoutId)
    }
  }, [selectedHeaderItem, contentRef])

  return (
    <Hidden
      below="xl"
      position="sticky"
      zIndex={navigationZIndex}
      top={0}
      backgroundColor={theme.colors.backgroundPrimary}
      data-testid="main-navigation"
    >
      <FocusTrap
        active={!!selectedHeaderItem}
        focusTrapOptions={{
          initialFocus: () => contentRef.current,
          clickOutsideDeactivates: true,
          onDeactivate: closeMenu,
        }}
      >
        <div>
          <Box
            display="flex"
            justifyContent="center"
            borderBottom={`1px solid ${theme.colors.borderPrimary}`}
          >
            <Grid
              display="flex"
              justifyContent="space-between"
              backgroundColor={theme.colors.backgroundPrimary}
              height={spacing.l}
            >
              <Gap size={spacing.none} display="flex" alignItems="center" flexWrap="false">
                <Box>
                  <StyledLink
                    display="block"
                    aria-label={t('fortumLogoLinkAriaLabel')}
                    data-testid="fortum-logo-link"
                    href={(logoLink && getLinkEntryUrl(logoLink)) ?? '/'}
                  >
                    <FortumLogo ph={0} role="link" size="s" color={theme.colors.brandPrimary} />
                  </StyledLink>
                </Box>
              </Gap>
              <Box display="flex" gap={spacing.xxxs}>
                {tabItem.length > 0 &&
                  tabItem.map((item) => (
                    <TabLink
                      key={item.id}
                      data-testid={`tabItem-${item.name}`}
                      item={item}
                      onClick={() => changeTab(item.id)}
                      active={checkActiveTab(item.id)}
                    />
                  ))}
              </Box>
              <Box display="flex" alignItems="center" flexWrap="false">
                {!isTopBarVisible && (
                  <>
                    <StyledIconButton
                      data-testid="search-button"
                      status="plain"
                      aria-label={t('search')}
                      color={theme.colors.brandPrimary}
                      icon={<Icon icon={IconSearch} />}
                      transition="opacity 300ms ease-in-out"
                      opacity={!isTopBarVisible ? 1 : 0}
                      onClick={onOpenSearch}
                      display={isEnabled ? 'block' : 'none'}
                    />
                    <Divider
                      opacity={!isTopBarVisible ? 1 : 0}
                      transition="opacity 300ms ease-in-out"
                    />
                  </>
                )}

                {customerType === 'Enterprise' ? (
                  <EnterpriseLoginButton
                    data-testid="enterprise-login-button"
                    color={theme.colors.brandPrimary}
                    label={isTopBarVisible ? t('login') : ''}
                    padding={!isTopBarVisible ? px2rem('12px') : spacing.xxs}
                  />
                ) : username ? (
                  <UserBadge
                    name={username}
                    padding={!isTopBarVisible ? px2rem('12px') : spacing.xxs}
                    isDropdown={isPrivate}
                    data-testid="user-badge"
                    router={router}
                    setNavigationZIndex={setNavigationZIndex}
                    megaMenuIsOpen={Boolean(selectedHeaderItem)}
                  />
                ) : (
                  <LoginButton
                    data-testid="login-button"
                    color={theme.colors.brandPrimary}
                    label={isTopBarVisible ? t('login') : ''}
                    padding={!isTopBarVisible ? px2rem('12px') : spacing.xxs}
                  />
                )}
              </Box>
            </Grid>
          </Box>
          <MenuWrapper
            width="100%"
            data-testid="main-menu-container"
            backgroundColor={theme.colors.backgroundPrimary}
            $isOpened={Boolean(selectedHeaderItem)}
            position="absolute"
            height={height}
            $duration={animationDuration}
          >
            <Box display="flex">
              <Box flex={1} />
              <div ref={contentRef} tabIndex={-1}>
                {mainNavigationItems?.items?.map(
                  (item) =>
                    item && (
                      <CatalogueContainer
                        fields={item}
                        key={item.name}
                        pathname={pathname}
                        visible={item?.name === selectedHeaderItem}
                        device="desktop"
                      />
                    ),
                )}
              </div>
              <Box
                flex={1}
                backgroundColor={
                  activeHeaderBarItem &&
                  isTypeColumnMenu(activeHeaderBarItem) &&
                  activeHeaderBarItem?.highlightColumn
                    ? theme.colors.backgroundSecondary
                    : theme.colors.backgroundPrimary
                }
              />
            </Box>
          </MenuWrapper>
        </div>
      </FocusTrap>
    </Hidden>
  )
}

const MenuWrapper = styled(Box)<{ $isOpened: boolean; height: string | number; $duration: number }>`
  ${(props) => css`
    overflow: hidden;
    transition:
      height ${props.$duration}ms ease-in-out,
      opacity ${props.$duration}ms ease-in-out,
      visibility ${props.$duration}ms ease-in-out;
  `}

  ${(props) =>
    props.$isOpened
      ? css`
          opacity: 1;
          visibility: visible;
          height: ${props.height};
        `
      : css`
          visibility: hidden;
          height: 0;
          opacity: 0;
        `}
`

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => css`
    &:hover {
      background: none;
      color: ${generateColorLuminances('hover', theme).bg};
    }

    &:active {
      background: none;
      color: ${generateColorLuminances('click', theme).bg};
    }
  `}
`

const Divider = styled(Box)<{ opacity: number }>`
  ${({ opacity, theme }) => css`
    opacity: ${opacity};
    width: 1px;
    height: 24px;
    background: ${theme.colors.backgroundTertiary};
  `}
`
